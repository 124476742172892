<template>
	<div class="editgiftdetails" v-loading="detailLoading">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
			<el-card class="box-card">
				<div slot="header" class="clearfix">
					<span>基本信息</span>
				</div>
				<div class="content">
					<el-form-item label="活动时间：" required>
						<el-form-item prop="StartTime" class="el-form-date-pick">
							<el-date-picker v-model="ruleForm.StartTime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
							 type="datetime" placeholder="开始时间" :picker-options='maxOptions' disabled>
							</el-date-picker>
						</el-form-item>
						<span style="margin:0 10px;">~</span>
						<el-form-item prop="EndTime" class="el-form-date-pick">
							<el-date-picker v-model="ruleForm.EndTime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
							 placeholder="结束时间" :picker-options='minOptions' disabled>
							</el-date-picker>
						</el-form-item>
					</el-form-item>
					<el-form-item label="活动限购">
						<div>
							<el-radio v-model="ruleForm.radioLimit" label="0" disabled>不限购</el-radio>
						</div>
						<div style="display: flex;align-items: center;">
							<el-radio v-model="ruleForm.radioLimit" label="1" disabled>
								限购
							</el-radio>
							<el-form-item :prop="ruleForm.radioLimit==1?'Quota':'nocheck'" :rules='ruleForm.radioLimit==1?rules.Quota:rules.nocheck'>
								<el-input :disabled="ruleForm.radioLimit==0" v-model="ruleForm.Quota" style="width: 100px;margin: 0 5px;"
								 onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
								 onblur="this.v();if (value==0||value=='-'){value =''}" disabled></el-input>件/人
							</el-form-item>
						</div>
						<div style="color: #909399;margin-top: 10px;">活动期间每个用户每种商品限购数量</div>
					</el-form-item>
					<el-form-item label="优惠券叠加：" required prop='IsSuperpositionCoupon'>
						<div>
							<el-radio v-model="ruleForm.IsSuperpositionCoupon" :label="true" disabled>叠加</el-radio>
						</div>
						<div>
							<el-radio v-model="ruleForm.IsSuperpositionCoupon" :label="false" disabled>不叠加</el-radio>
						</div>
						<div style="color: #909399;margin-top: 10px;">开启优惠券叠加后，本场活动商品可同时专享价、优惠券优惠</div>
					</el-form-item>

					<el-form-item label="活动商品：" required>
						<!-- <span style="color:#409EFF;margin-left:15px;cursor: pointer;" @click="handlePrizePro">选择商品</span> -->
						<!-- 抽奖活动表格 -->
						<div class="productInfo" style="display: flex;width: 100%;"  v-if='checkShowId'>
							<img :src="imgUrl + ruleForm.ImgUrl +'@!cut64'" v-if='ruleForm.ImgUrl' />
							<div style="display: flex;margin-left:10px;width: 60%;">
								 <!-- v-if="ruleForm.ProductType==1" -->
								<div style="color: #f00;font-size: 12px;width:40px" v-if="ruleForm.ProductType==1">[组合]</div>
								<div style="width:90%;" class="ellipsisStyle">
									<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{ruleForm.ProductName}}</pre>
								</div>
							</div>
						</div>
						<el-table :data="ruleForm.ProductSpecList" style="width:1000px;margin:15px 0" v-loading="loading" ref="multipleTable"
						 :row-key="rowKeys">
							<el-table-column prop="Name" label="规格">
								<template slot-scope="scope">
									<div v-if='scope.row.IsDefault'>默认规格</div>
									<div class="oneSpless ellipsisCommon" v-else>
										{{scope.row.SpecValue}}
										<span v-if="scope.row.SpecValue2">;{{scope.row.SpecValue2}}</span>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="RoomId" label="价格">
								<template slot-scope="scope">
									<div>￥{{scope.row.Price}}</div>
								</template>
							</el-table-column>
							<el-table-column prop="Stock " label="库存">
								<template slot-scope="scope">
									<div>￥{{scope.row.Stock}}</div>
								</template>
							</el-table-column>

							<el-table-column prop="ExclusivePrice" label="专享价">
								<template slot-scope="scope">
									<div>
										<input v-model="scope.row.ExclusivePrice" style="padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
										 type="number" @keydown="handleInput2" disabled>
										</input> 元
									</div>
								</template>
							</el-table-column>
						</el-table>
					</el-form-item>
				</div>
			</el-card>
		</el-form>


		<div class="bottom-save-btn">
			<el-button style="width:240px" @click="canselEdit">取消</el-button>
		</div>
	</div>
</template>

<script>
	import config from '@/config/index'
	import{activityCommunityGroupInfo} from '@/api/TurnTomySelf.js'
	// import selectProduce from '@/components/SelectSingleProduce/lottery'
	import selectProduce from './turntableActivities'
	export default {
		components: {
			selectProduce
			// giftdataProduct,
			// turntableActivities,
		},
		data() {
			var checkno = (rule, value, callback) => {
				return callback();
			};
			var limitNumberCheck = (rule, value, callback) => {
				console.log(value, 789456)
				if (!value && value !== 0) {
					return callback(new Error('请输入限购数量'));
				} else if (value < 0 || value > 99999) {
					return callback(new Error('限购数量请设置在0~99999之间'));
				} else {
					return callback();
				}
			};
			return {
				ProductSpecList: [],
				loading: false,
				couponData: {},
				imgUrl: config.IMG_BASE,
				detailLoading: false,
				ruleForm: {
					StartTime: '',
					EndTime: '',
					radioLimit: '0',
					Quota: '',
					ProductSpecList: [],
					IsSuperpositionCoupon: null,
					ProductType: '',
					ProductName: '',
					ImgUrl: '',
					// Delstatus:'',
				},
				rules: {
					StartTime: [{
						required: true,
						message: '请选择开始日期',
						trigger: 'blur'
					}],
					EndTime: [{
						required: true,
						message: '请选择结束日期',
						trigger: 'blur'
					}],
					Quota: [{
						required: true,
						validator: limitNumberCheck,
						trigger: 'blur'
					}],
					nocheck: [{
						validator: checkno,
						trigger: 'change'
					}],
					IsSuperpositionCoupon: [{
						required: true,
						message: '请配置优惠券叠加方式',
						trigger: 'change'
					}],
				},
				proPage: {
					current: 1,
					total: 0,
					size: 5
				},
				selectProShow: false,
				proCheckedId: 0,
				selectProductlist: [], //初始化活动数据
				currentEditStatus: null,
				currentEditType: null,
				saveLoading: false,
				checkShowId:0,
			}
		},
		created() {
			this.currentEditStatus = this.$route.query.status
			this.currentEditType = this.$route.query.type
			this.getInformation()
		},
		computed: {
			minOptions: function() {
				let limitTime = this.ruleForm.StartTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(new Date(limitTime).toLocaleDateString())
						} else {
							return time < new Date(currentDate)
						}
					},
				}
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.ruleForm.EndTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return (
								time > new Date(new Date(limitTime).toLocaleDateString()) ||
								time < new Date(currentDate)
							)
						} else {
							return time < new Date(currentDate)
						}
					}
				}
			}
		},
		methods: {
			// 取消
			canselEdit() {
				this.$router.push({
					path: '/market/association/associateShare'
				});
			},
			handleInput2(e) {
				// 通过正则过滤小数点后两位
				e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null

			},
			rowKeys(row) {
				return row.ProductSpecId
			},
			// 初始化数据
			async getInformation() {
				try {
					this.detailLoading = true;
					let data = {
						Id: this.$route.query.Id
					}
					let result = await activityCommunityGroupInfo(data)
					this.ruleForm = result.Result
					if (this.ruleForm.Quota > 0) {
						this.ruleForm.radioLimit = '1'
					} else {
						this.ruleForm.radioLimit = '0'
					}
					if (this.$route.query.type == 2) {
						this.ruleForm.StartTime = ''
						this.ruleForm.EndTime = ''
					}
					this.checkShowId = this.ruleForm.ProductSpecList[0].ProductId
				} catch (e) {
					console.log(e)
				} finally {
					this.detailLoading = false
				}

			},
		}
	}
</script>

<style lang="less">
	.editgiftdetails {
		padding: 10px;
		background: #fff;

		.el-form-date-pick {
			display: inline-block;
		}

		.towLines {
			word-break: break-all;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		.lookImgsWidth {
			width: 460px;
		}

		.dialog-name-content {
			display: flex;
			flex-direction: row;

			img {
				width: 60px;
				height: 60px;
				object-fit: contain;
				margin-right: 10px;
			}

			.name {
				width: 200px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}

			span {
				color: #E51C23;
				font-size: 12px;
			}
		}

		.bottom-save-btn {
			position: fixed;
			width: 100%;
			background: #fff;
			bottom: 0;
			text-align: center;
			padding: 20px 0;
			z-index: 100;
			box-shadow: 13px 1px 6px #999;
		}

	}
</style>
